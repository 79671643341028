
import { Component, Vue, Watch } from 'vue-property-decorator';
import { notificationStore } from '@/store';
import { NotificationModel } from '@/models/notification/NotificationModel';

@Component
export default class SystemNotifications extends Vue{

	get Notifications(): NotificationModel[]{
		return notificationStore.errorNotifications;
	}
	get NextNotification(): NotificationModel | null{
		const [ notification ] = this.Notifications;
		return notification ?? null;
	}
	get NextNotificationColor(): string{
		return 'baColorAlertRed';
	}

	get ShowNotification(): boolean{
		return this.NextNotification !== null;
	}


	timeout: number | null = null;
	@Watch('NextNotification', { immediate: true }) startClearTimeout(): void{
		if(this.timeout !== null) return;
		this.timeout = setTimeout(() => this.execClearTimeout(), 5000);
	}
	execClearTimeout(): void{
		if(this.timeout) clearTimeout(this.timeout);
		this.timeout = null;
		this.clearErrorNotifications();
	}

	async clearErrorNotifications(): Promise<void>{
		await notificationStore.clearErrorNotifications();
	}

	beforeDestroy(): void{
		if(this.timeout) clearTimeout(this.timeout);
	}
}
