
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AuthMixin, AthleteRoutingMixin, MyAthleteMixin, MyTeamsMixin, VuetifyMixin, FeatureFlagMixin } from '@/mixins';
import { UpgradeNowMixin } from '@/mixins/UpgradeNowMixin';

import BestAthletesLogo from '../ui/BestAthletesLogo.vue';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import TeamNavItems from './TeamNavItems.vue';
import EventsNavItems from './EventsNavItems.vue';
import Feature from '@/components/ui/Feature.vue';

import { mdiClipboardPulseOutline, mdiClipboardListOutline, mdiHeadSnowflakeOutline, mdiHeadCogOutline } from '@mdi/js';
import UserRecruitingProfileSvg from "@/components/svg/UserRecruitingProfileSvg.vue";
import ClipboardSvg from '../svg/ClipboardSvg.vue';
import BarChartSvg from '../svg/BarChartSvg.vue';
import YoutubeSvg from '@/components/svg/YoutubeSvg.vue';
import UserSelfReportSvg from '../svg/UserSelfReportSvg.vue';
import SchoolSvg from '../svg/SchoolSvg.vue';

import { userStore } from '../../store';
import { athleteProfile } from '../../store';

@Component({
	components: {
		BestAthletesLogo,
		SideNavSectionTitle,
		TeamNavItems,
		EventsNavItems,
		Feature,
		ClipboardSvg,
		BarChartSvg,
		UserSelfReportSvg,
		UserRecruitingProfileSvg,
		YoutubeSvg,
		SchoolSvg,
	},
})
export default class AthleteSideNav extends Mixins(AuthMixin, AthleteRoutingMixin, MyAthleteMixin, MyTeamsMixin, UpgradeNowMixin, VuetifyMixin, FeatureFlagMixin){
	mini = false;

	iconDashboard = mdiClipboardPulseOutline;
	iconScouting = mdiClipboardListOutline;
	iconMindset = mdiHeadSnowflakeOutline;
	iconPersonality = mdiHeadCogOutline;

	@Prop({ default: true, type: Boolean }) value: boolean;
	@Prop({ default: 'baColorDeepBlue' }) color: string;

	async created(): Promise<void>{
		await userStore.loadAthleteProfiles();
	}
	
	get Color(): string{
		return this.getColor(this.color);
	}	
	get NoComparativeData(): boolean{
		return athleteProfile.athleteAssessmentData === null;
	}
}
