
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mdiAccountMultiplePlus } from '@mdi/js';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import DatePreview from '../ui/DatePreview.vue';
import * as Routes from '../../../types/constants/web_client_user.routes';
import { TeamModel } from '../../models/team';
import { Route } from 'vue-router';

@Component({
	components: { DatePreview, SideNavSectionTitle },
})
export default class TeamNavItems extends Vue{
	mdiAccountMultiplePlus = mdiAccountMultiplePlus;
	@Prop({ default: false }) mini: boolean;
	@Prop({ default: false }) ready: boolean;
	@Prop({ required: true }) teams: TeamModel[];
	@Prop({ default: null }) athleteId: string | null;
	@Prop({ default: null }) coachId: string | null;
	get HasTeams(): boolean{
		return this.teams.length > 0;
	}
	getTeamRoute(teamId: string): Partial<Route>{
		if(this.coachId !== null){
			return {
				name: Routes.TeamDashboard,
				params:{
					teamId,
					coachId: this.coachId,
				}
			};
		}
		return {
			name: Routes.AthleteTeamDashboard,
			params:{
				teamId,
				athleteId: this.athleteId,
			}
		};
	}
	get JoinTeamRoute(): Partial<Route>{
		if(this.athleteId !== null){
			return {
				name: Routes.AthleteTeamJoin,
				params:{
					athleteId: this.athleteId,
				}
			}
		}
		return {
			name: Routes.TeamJoin,
		};
	}
}
