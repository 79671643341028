<template>
	<v-img v-bind="$attrs" contain :src="img">
		<slot></slot>
	</v-img>
</template>
<script>
import img from '@/assets/img/user-self-report.svg'
export default{
	data(){
		return { img }
	}
}
</script>